import $ from "jquery";
import '../../assets/libs/bootstrap-5.0.2-dist/js/bootstrap.bundle.min.js';
import * as AOS from '../../assets/libs/aos';
import Footer from '@/components/footer/Footer'
import Header from '@/components/header/header';

export default {
    name: "Solar",
    data (){
        return{
            // imageBuilding: require('@/assets/img/solarPlus/imgBuilding.png')
            imageBuilding: require('@/assets/img/build-color/building-img-color1.png'),
            // pdfLink: require("@/assets/2111SolarTAGPLUS.pdf"),
        }
    },
    mounted () {
        document.title = "AGC - Solar";
        AOS.init();
        resize();
        function resize(){
            if(window.innerWidth<800) $('.footer').css({'position':'relative','top':'0px'});
        }
        $('img[alt="color"]').on('click',(evt)=>{
            $('img[alt="color"].active').removeClass("active"); 
            $('#'+evt.target.id).addClass("active"); 
            this.imageBuilding = require(`@/assets/img/build-color/building-img-${evt.target.id}.png`)
            console.log(this.imageBuilding)
        })
    },
    components: {
        Footer,Header
    }
};